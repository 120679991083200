<template>
<div>
  <!--BOTONERA  -->
  <div class="row justify-content-end mr-2">
    <b-button-group>
      <b-button  variant="primary" @click="OpenModal()" pill>
        <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
          <b-icon icon="plus"/> Nuevo
        </div>
        <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
          <b-icon icon="plus"/>
        </div>
      </b-button>
    </b-button-group>
  </div>
  <!--BOTONERA  -->
  <!--BARRA DE BUSQUEDA  -->
  <searchingBar :components="['filter',  'pages']"
                @Fectch="FetchTable"
                @filterValue="filterValue = $event"
                @sortValue="tableObj.sortValue = $event"
                @pageSize="tableObj.pagination.pageSize = $event"
                @currentPageValue="tableObj.pagination.current = $event">
    <div class="row justify-content-start mb-4" slot="customFilters">
      <div class="col" >
        <globalPlantFilter @onChangeValue="FetchTable"  @filterValue="filterWorkCenter = $event"/>
      </div>
    </div>
  </searchingBar>
  <!--BARRA DE BUSQUEDA  -->
  <!--TABLA-->
  <a-table :columns="tableObj.columns"
           :data-source="tableObj.data"
           :pagination="tableObj.pagination"
           :loading="tableObj.loading"
           @change="FetchTable"
           :row-key="record => record.id">
    <span slot="workCenters" slot-scope="workCenters">{{workCenters.name}}</span>
    <a-table
      slot="expandedRowRender"
      slot-scope="row"
      :columns="innerColumns"
      :data-source="row.checksEquipmentQuestions"
      :pagination="false"
      :row-key="record => record.id"
    >
      <template slot="has_option" slot-scope="has_option">
        <span v-if="has_option === 'options'">Simbología</span>
        <span v-if="has_option === 'text'">Texto</span>
        <span v-if="has_option === 'both'">Simbología + Texto</span>
      </template>
      <template slot="action" slot-scope="data">
        <b-button  variant="secondary" @click="OpenQuestionModal(data,row)" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Editar Pregunta">
            <b-icon icon="pencil-square"/> Editar Pregunta
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Editar Pregunta">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
      </template>
    </a-table>
    <template slot="details" slot-scope="data">
      <a-row v-for="(item, index) in data.options" :key="index">
        <a-col>
          <strong>{{item.value}}: </strong>
          <span>{{ item.text }}</span>
        </a-col>
      </a-row>
    </template>
    <template slot="action" slot-scope="data">
      <b-button  variant="secondary" @click="OpenModal(data)" pill>
        <div class="d-none d--block" v-b-tooltip.hover title="Editar">
          <b-icon icon="pencil-square"/> Editar
        </div>
        <div class="d--none" v-b-tooltip.hover title="Editar">
          <b-icon icon="pencil-square"/>
        </div>
      </b-button>
      <b-button  variant="info" @click="OpenQuestionModal({},data)" pill>
        <div class="d-none d-md-block" v-b-tooltip.hover title="Pregunta">
          <b-icon icon="plus"/> Pregunta
        </div>
        <div class="d-md-none" v-b-tooltip.hover title="Pregunta">
          <b-icon icon="plus"/>
        </div>
      </b-button>
    </template>
  </a-table>
  <!--TABLA-->
  <!-- MODAL -->
  <b-modal title="Detalle de Check List" ref="modalCheck" size="lg">
    <b-container fluid>
      <!--FORMULARIO-->
      <a-form-model :model="resourceObj.form" :rules="formRules" ref="formCheck"
                    layout="vertical" :wrapper-col="{ span: 24}">
        <!--TEXT - Nombre del Check List-->
        <a-form-model-item label="Nombre del Check List" ref="name"  prop="name" >
            <a-input v-model="resourceObj.form.name" placeholder="Nombre del Check List" size="large"/>
        </a-form-model-item>
        <!--TEXT -Nombre del Check List -->
        <!--SELECT -Planta -->
        <a-form-model-item label="Planta" ref="workCenters" prop="workCenters" >
          <a-select v-model="resourceObj.form.workCenters"
                    placeholder="Planta"
                    show-search :filter-option="filterOption">
            <a-select-option  v-for="option in workCentersCombo" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--SELECT -Planta -->
        <!--OPCIONES - SIMBOLOGIA -->
        <div class="row my-4 align-middle justify-content-center">
          <span v-if="resourceObj.form.options.length == 0" class="text-danger">Debe agregar al menos una opción de simbología</span>
          <br>
        </div>
        <b-button  variant="primary"
                   v-if="resourceObj.form.options.length == 0"
                   @click="addDomain" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Agregar">
            <b-icon icon="plus"/> Agregar Símbolo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Agregar">
            <b-icon icon="plus"/>
          </div>
        </b-button>
        <div class="border border-primary rounded p-4 mb-3"
             v-for="(option, index) in resourceObj.form.options" :key="index">
          <a-row align="middle" justify="center">
            <a-col :sm="{ span: 11 }" :md="{ span:10}">
              <a-form-model-item label="Símbolo">
                <a-input  v-model.trim="option.value" placeholder="Clave" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 11 }" :md="{ span:10}">
              <a-form-model-item label="Valor">
                <a-input  v-model.trim="option.text" placeholder="Valor" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 2 }" :md="{ span:4}">
              <b-button  variant="secondary"
                         v-if="resourceObj.form.options.length > 0"
                         :disabled="resourceObj.form.options.length === 0"
                         @click="removeDomain(index)">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="dash-circle"/>
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                  <b-icon icon="dash-circle"/>
                </div>
              </b-button>
              <b-button  variant="primary"
                         v-if="option.value !=='' && option.text !==''"
                         @click="addDomain">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Agregar">
                  <b-icon icon="plus"/>
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Agregar">
                  <b-icon icon="plus"/>
                </div>
              </b-button>
            </a-col>
          </a-row>
        </div>
        <!--OPCIONES - SIMBOLOGIA -->
        <!--COMPONENTE ADMINISTRADOR DE ARHIVOS -->
        <filesUpload v-if="resourceObj.form.id"
                     v-model="resourceObj.form.image"
                     @Success="FetchTable()"
                     label="Ilustraciones" labelBtn="Cargar Ilustración" domReference="image"
                     strategy="ChecksIllustrations" :id="resourceObj.form.id"
                     :fileslimit="1" :maxMBSize="2"
                     aceptedFiles="image/*,.pdf" mimes="jpg,bmp,png" mimetypes="image/*"
        />
        <!--COMPONENTE ADMINISTRADOR DE ARHIVOS -->
        <!--BOTONERA  -->
        <div class="row justify-content-end mr-2" v-if="hasSimbols">
          <b-button-group>
            <b-button  variant="primary" @click="Submit()" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
            </b-button>
          </b-button-group>
        </div>
        <!--BOTONERA  -->
      </a-form-model>
      <!--FORMULARIO-->
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseModal"/>
    </template>
  </b-modal>
  <!-- MODAL -->
  <!-- ************** ChecksEquipmentQuestion *************** -->
  <b-modal title="Detalle de Pregunta" ref="modalQuestion" size="lg">
    <b-container fluid>
      <a-form-model :model="resourceObjQ.form" :rules="formRulesQ" ref="formQuestions"
                    layout="vertical" :wrapper-col="{ span: 24}">
        <!--SELECT -Check List -->
        <a-form-model-item label="Check List" ref="checksEquipmentSources" prop="checksEquipmentSources" >
          <a-select v-model="resourceObjQ.form.checksEquipmentSources"
                    placeholder="Seleccionar Check List" disabled
                    show-search :filter-option="filterOption">
            <a-select-option  v-for="option in questionCombo" :key="option.id" :value="option.id" >
              {{option.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <!--SELECT -Check List -->
        <!--TEXT - Pregunta-->
        <a-form-model-item label="Pregunta" ref="question"  prop="question">
            <a-input v-model="resourceObjQ.form.question" placeholder="Pregunta" size="large"/>
        </a-form-model-item>
        <!--TEXT -Pregunta -->
        <!--RADIO -has_option -->
        <a-form-model-item label="Tipo de Respuesta" ref="has_option"  prop="has_option">
          <a-radio-group v-model="resourceObjQ.form.has_option" >
            <a-radio-button value="options">Simbología</a-radio-button>
            <a-radio-button value="text">Texto</a-radio-button>
            <a-radio-button value="both">Simbología + Texto</a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <!--RADIO -has_option -->

        <!--BOTONERA  -->
        <div class="row justify-content-end mr-2">
          <b-button-group>
            <b-button  variant="primary" @click="SubmitQ()" pill>
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
            </b-button>
            <b-button  variant="danger" @click="DeleteQ" pill>
                <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                    <b-icon icon="trash"/> Eliminar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                    <b-icon icon="trash"/>
                </div>
            </b-button>
          </b-button-group>
        </div>
        <!--BOTONERA  -->
      </a-form-model>
    </b-container>
    <template v-slot:modal-footer>
      <simple-modal-footer @ClickEvent="CloseQuestionModal"/>
    </template>
  </b-modal>
  <!-- ************** ChecksEquipmentQuestion *************** -->
</div>
</template>

<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'checksEquipmentSources',
  mixins: [fractalMixin],
  props: {
    equipment_type: {
      type: [String],
      required: true,
      validator: (value) => [
        'Equipos Móviles',
        'Equipos de Laboratorio',
        'Equipos de Seguridad',
        'Trituradoras',
        'Instalaciones',
      ].indexOf(value) !== -1,
    },
  },
  computed: {
    hasSimbols() {
      return this.resourceObj.form.options.length > 0
    },
  },
  data() {
    return {
      filterValue: '',
      filterWorkCenter: '',
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Nombre',
            dataIndex: 'name',
            class: 'text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Planta',
            dataIndex: 'workCenters',
            class: 'text-center',
            scopedSlots: { customRender: 'workCenters' },
            // width: '20%',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'text-center',
            scopedSlots: { customRender: 'details' },
            // width: '20%',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
      resourceObj: {
        resourceType: 'checksEquipmentSources',
        form: {
          id: '',
          name: '',
          options: [],
          equipment_type: '',
          image: [],
          workCenters: undefined,
        },
        relationships: ['workCenters'],
      },
      formRules: {
        name: [{
          required: true,
          message: 'El campo Nombre es obligatorio.',
          trigger: 'change',
        }],
        workCenters: [{
          required: true,
          message: 'El campo Planta es obligatorio.',
          trigger: 'change',
          min: 1,
        }],
        // options: [{
        //   required: true,
        //   type: 'array',
        //   message: 'Debe agregar al menos una opción de simbología.',
        //   trigger: 'change',
        // }],
      },
      spinnerLoad: false,
      workCentersCombo: [],
      // ChecksEquipmentQuestion
      questionCombo: [],
      innerColumns: [
        {
          title: 'Pregunta',
          dataIndex: 'question',
          class: 'text-center',
        },
        {
          title: 'Tipo de Respuesta',
          dataIndex: 'has_option',
          class: 'text-center',
          scopedSlots: { customRender: 'has_option' },
        },
        {
          title: 'Acción',
          dataIndex: '',
          key: 'x',
          class: 'text-center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      resourceObjQ: {
        resourceType: 'checksEquipmentQuestions',
        form: {
          id: '',
          question: '',
          has_option: '',
          checksEquipmentSources: undefined,
        },
        relationships: ['checksEquipmentSources'],
      },
      formRulesQ: {
        question: [{
          required: true,
          message: 'El campo Pregunta es obligatorio.',
          trigger: 'change',
        }],
        has_option: [{
          required: true,
          message: 'El campo Tipo de Respuesta es obligatorio.',
          trigger: 'change',
        }],
      },
    }
  },
  methods: {
    // Local Helpers
    isMobile() {
      return this.equipment_type === 'Equipos Móviles'
    },
    isLaboratory() {
      return this.equipment_type === 'Equipos de Laboratorio'
    },
    isSecurity() {
      return this.equipment_type === 'Equipos de Seguridad'
    },
    // Local Helpers
    // Logica de ChecksEquipmentSource
    async FetchCombos() {
      this.workCentersCombo = await this.GetResource('/workCenters', { sort: 'name' })
      const params = {
        sort: 'name',
        'filter[equipment_type]': this.equipment_type,
      }
      if (this.filterWorkCenter !== '') params['filter[workCenters]'] = this.filterWorkCenter
      this.questionCombo = await this.GetResource('/checksEquipmentSources', params)
    },
    async FetchTable(pagEvent = {}) {
      const params = {
        sort: this.tableObj.sortValue,
        include: 'workCenters,checksEquipmentQuestions',
        'filter[equipment_type]': this.equipment_type,
      }
      if (this.filterValue !== '') params['filter[name]'] = this.filterValue
      if (this.filterWorkCenter !== '') params['filter[workCenters]'] = this.filterWorkCenter
      await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
    },
    OpenModal(data = {}) {
      this.$refs.modalCheck.show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.resetChecksEquipmentSource()
      } else {
        // Update case
        this.resourceObj.form = {
          id: data.id,
          name: data.name,
          options: data.options,
          equipment_type: this.equipment_type,
          image: (data.image === null) ? [] : data.image,
          workCenters: data.workCenters.id.toString(),
        }
      }
    },
    CloseModal() {
      this.$refs.modalCheck.hide()
      this.resetChecksEquipmentSource()
    },
    Submit() {
      this.$refs.formCheck.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            await this.PostResource(this.resourceObj, this.SuccessCallback, this.ErrorCallback)
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, this.ErrorCallback)
        } else return false
      })
    },
    resetChecksEquipmentSource() {
      this.resourceObj.form = {
        id: '',
        name: '',
        options: [],
        equipment_type: this.equipment_type,
        image: [],
        workCenters: undefined,
      }
    },
    Delete() {},
    removeDomain(index) {
      // const index = this.resourceObj.form.options.indexOf(item)
      console.log(index)
      if (index !== -1) {
        this.resourceObj.form.options.splice(index, 1)
      }
    },
    addDomain() {
      this.resourceObj.form.options.push({
        value: '',
        text: '',
        // key: Date.now(),
      })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    async ErrorCallback() {
      this.spinnerLoad = false
      this.CloseModal()
    },
    // Logica de ChecksEquipmentSource
    // Logica de ChecksEquipmentQuestion
    OpenQuestionModal(data = {}, check) {
      this.$refs.modalQuestion.show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.resetQuestion(check)
      } else {
        this.resourceObjQ.form.id = data.id
        this.resourceObjQ.form.question = data.question
        this.resourceObjQ.form.has_option = data.has_option
        this.resourceObjQ.form.checksEquipmentSources = check.id
      }
    },
    CloseQuestionModal() {
      this.$refs.modalQuestion.hide()
      this.resetQuestion()
    },
    SubmitQ() {
      this.$refs.formQuestions.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObjQ.form.id === '') {
            await this.PostResource(this.resourceObjQ, this.SuccessCallbackQ, this.ErrorCallbackQ)
          } else await this.PutResource(this.resourceObjQ, this.SuccessCallbackQ, this.ErrorCallbackQ)
        } else return false
      })
    },
    resetQuestion(check) {
      this.resourceObjQ.form = {
        id: '',
        question: '',
        has_option: '',
        checksEquipmentSources: check ? check.id : undefined,
      }
    },
    async DeleteQ() {
      await this.deleteQuestion(this.resourceObjQ.resourceType, this.resourceObjQ.form.id, this.SuccessCallbackQ)
    },
    async SuccessCallbackQ() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseQuestionModal()
    },
    async ErrorCallbackQ() {
      this.spinnerLoad = false
      this.CloseQuestionModal()
    },
    // Logica de ChecksEquipmentQuestion
  },
  async mounted() {
    await this.FetchTable()
    await this.FetchCombos()
  },
}
</script>

<style scoped>

</style>
