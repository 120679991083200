<template>
  <div>
    <!--BOTONERA  -->
    <div class="row justify-content-end mr-2">
      <b-button-group>
        <b-button  variant="primary" @click="OpenModal()" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/> Nuevo
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Nuevo">
            <b-icon icon="plus"/>
          </div>
        </b-button>
      </b-button-group>
    </div>
    <!--BOTONERA  -->
    <!--BARRA DE BUSQUEDA  -->
    <searchingBar :components="['filter', 'sort', 'pages']"
                  :sortByOptions="sortByOptions"
                  @Fectch="FetchTable"
                  @filterValue="filterValue = $event"
                  @sortValue="tableObj.sortValue = $event"
                  @pageSize="tableObj.pagination.pageSize = $event"
                  @currentPageValue="tableObj.currentPage = $event">
      <div class="row justify-content-start" slot="customFilters">
        <div class="col-sm-12 col-md-6" >
          <globalPlantFilter @onChangeValue="FetchTable"  @filterValue="filterWorkCenter = $event"/>
        </div>
        <div class="col-sm-12 col-md-6" v-if="!isMobile()" >
          <b-form-group
            id="familia-group"
            label="Familia"
            label-for="familia"
          >
            <b-form-select v-model="filterFamilies" :options="familiesCombo"
                           id="familia" label="Familia" @change="FetchTable()"
                           value-field="id" text-field="name">
              <template slot="first">
                <b-form-select-option value="" >Todas las Familias</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </div>
      </div>
    </searchingBar>
    <!--BARRA DE BUSQUEDA  -->
    <!--TABLA-->
    <a-table :columns="tableObj.columns"
             :data-source="tableObj.data"
             :pagination="tableObj.pagination"
             :loading="tableObj.loading"
             @change="FetchTable"
             :row-key="record => record.id">
      <span slot="workCenters" slot-scope="workCenters">{{workCenters.name}}</span>
      <template slot="details" slot-scope="data">
        <a-descriptions :title="`Clave del Equipo: ${data.code}`"  bordered size="small"
                        :column="{ sm: 1, lg: 2}">
          <!-- Informacion de visual inpection -->
          <a-descriptions-item label="Check List" v-if="data.checksEquipmentSources">
            <strong>{{ data.checksEquipmentSources.name }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="No.serie"> <strong>{{data.serial_number}}</strong></a-descriptions-item>
          <a-descriptions-item :label="isMobile ? 'Fecha de ingreso a planta' : 'Fecha de adquisición del equipo'">
            <strong>{{ moment(data.dated_at).format('DD/MM/YYYY HH:mm') }}</strong>
          </a-descriptions-item>
          <a-descriptions-item label="Estatus">
            <span v-if="data.status" class="rounded p-1 bg-success text-white">Activo</span>
            <span v-else class="rounded p-1 bg-warning text-white">Inactivo</span>
          </a-descriptions-item>
          <template v-if="isMobile()">
            <a-descriptions-item label="Modelo"> <strong>{{data.specific_data.model}}</strong></a-descriptions-item>
            <a-descriptions-item label="Año"> <strong>{{data.specific_data.year}}</strong></a-descriptions-item>
            <a-descriptions-item label="Vigencia de Poliza de seguro">
              <strong> {{data.specific_data.policy_validity}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Vigencia tajeta de circulación">
              <strong>{{data.specific_data.card_validity}}</strong>
            </a-descriptions-item>
            <a-descriptions-item label="Capacidad de Carga">
              <strong>{{data.specific_data.loading_capacity}} - TON</strong>
            </a-descriptions-item>
          </template>
          <template v-else>
            <a-descriptions-item label="Familia"> {{data.families.name}}</a-descriptions-item>
            <a-descriptions-item label="Especificación Técnica"> {{data.technical_specification}}</a-descriptions-item>
            <a-descriptions-item label="Detalles"> {{data.details}}</a-descriptions-item>
          </template>
        </a-descriptions>
      </template>
      <template slot="action" slot-scope="data">
        <b-button  class="mr-1 mb-1" variant="secondary" @click="OpenModal(data)" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/> Editar
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Editar">
            <b-icon icon="pencil-square"/>
          </div>
        </b-button>
        <b-button  @click="GoToCheckLis(data)"
                   v-if="data.checksEquipmentSources"
                   class="mb-1" variant="warning"
                   pill>
            <div class="d-none d-md-block" v-b-tooltip.hover title="Realizar Check List">
                <b-icon icon="card-checklist"/> Check List
            </div>
            <div class="d-md-none" v-b-tooltip.hover title="Realizar Check List">
                <b-icon icon="card-checklist"/>
            </div>
        </b-button>
        <b-button  @click="GoToHistory(data.id)"
                   class="mb-1" variant="info" pill>
          <div class="d-none d-md-block" v-b-tooltip.hover title="Bitácora">
            <b-icon icon="clock-history"/> Bitácora
          </div>
          <div class="d-md-none" v-b-tooltip.hover title="Bitácora">
            <b-icon icon="clock-history"/>
          </div>
        </b-button>
      </template>
    </a-table>
    <!--TABLA-->
    <!-- MODAL -->
    <b-modal title="Detalle de auxiliar" ref="modal-Form" size="lg">
        <b-container fluid>
          <!--FORMULARIO-->
          <a-form-model :model="resourceObj.form" :rules="formRules" ref="ruleForm"
              layout="vertical" :wrapper-col="{ span: 24}">
            <!--  CAMPOS COMUNES-->
            <!--TEXT - Clave del Equipo-->
            <a-form-model-item label="Clave del Equipo" ref="code"  prop="code" >
                <a-input v-model.trim="resourceObj.form.code" placeholder="Clave del Equipo" size="large"/>
            </a-form-model-item>
            <!--TEXT -Clave del Equipo -->
            <!--TEXT - Nombre del equipo-->
            <a-form-model-item label="Nombre del equipo" ref="name"  prop="name" >
                <a-input v-model.trim="resourceObj.form.name" placeholder="Nombre del equipo" size="large"/>
            </a-form-model-item>
            <!--TEXT -Nombre del equipo -->
            <!--SELECT -Planta -->
            <a-form-model-item label="Planta" ref="workCenters" prop="workCenters" >
                <a-select v-model="resourceObj.form.workCenters" @change="FetchCheckListsCombos"
                            placeholder="Planta"
                            show-search :filter-option="filterOption">
                    <a-select-option  v-for="option in workCentersCombo" :key="option.id" :value="option.id" >
                      {{option.name}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <!--SELECT -Planta -->
            <!--SELECT -Check List -->
            <a-form-model-item label="Check List" ref="checksEquipmentSources" prop="checksEquipmentSources" >
                <a-select v-model="resourceObj.form.checksEquipmentSources"
                            placeholder="Seleccionar Check List" allowClear
                            show-search :filter-option="filterOption">
                    <a-select-option  v-for="option in checksEquipmentSources" :key="option.id" :value="option.id" >
                      {{option.name}}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <!--SELECT -Check List -->
            <!--TEXT - No.Serie-->
            <a-form-model-item label="No.Serie" ref="serial_number"  prop="serial_number" >
              <a-input v-model.trim="resourceObj.form.serial_number" placeholder="No.Serie" size="large"/>
            </a-form-model-item>
            <!--TEXT -No.Serie -->
            <a-row align="middle" justify="center">
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <!--DATEPICKER - Fecha de ingreso-->
                <a-form-model-item :label="isMobile() ? 'Fecha de ingreso a planta' : 'Fecha de adquisición del equipo'"
                                   ref="dated_at" prop="dated_at">
                  <a-date-picker  v-model="resourceObj.form.dated_at" placeholder="Fecha de ingreso"
                                  format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD" style="width: 100%"/>
                </a-form-model-item>
                <!--DATEPICKER - Fecha de ingreso -->
              </a-col>
              <a-col :sm="{ span: 24 }" :md="{ span:12}">
                <!--SELECT -Estatus -->
                <a-form-model-item label="Estatus" ref="status" prop="status" >
                  <a-select v-model="resourceObj.form.status"
                            placeholder="Estatus"
                            show-search :filter-option="filterOption">
                    <a-select-option  v-for="option in statusCombos" :key="option.value" :value="option.value" >
                      {{option.label}}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!--SELECT -Estatus -->
              </a-col>
            </a-row>
            <!--  CAMPOS COMUNES-->
            <!--  CAMPOS DE SEGURIDAD Y LABORATORIO-->
            <template v-if="!isMobile()">
              <hr class="bg-primary">
              <!--SELECT -Familia -->
              <a-form-model-item label="Familia" ref="families" prop="families" >
                  <a-select v-model="resourceObj.form.families"
                              placeholder="Familia"
                              show-search :filter-option="filterOption">
                      <a-select-option  v-for="option in familiesCombo" :key="option.id" :value="option.id" >
                        {{option.name}}
                      </a-select-option>
                  </a-select>
              </a-form-model-item>
              <!--SELECT -Familia -->
              <!--TEXT-AREA - Especificación técnica-->
              <a-form-model-item label="Especificación técnica" ref="technical_specification"  prop="technical_specification" >
                  <a-textarea v-model.trim="resourceObj.form.technical_specification" :auto-size="{ minRows: 4 }"/>
              </a-form-model-item>
              <!--TEXT-AREA -Especificación técnica -->
              <!--TEXT-AREA - Detalles-->
              <a-form-model-item label="Detalles" ref="details"  prop="details" >
                <a-textarea v-model.trim="resourceObj.form.details" :auto-size="{ minRows: 4 }"/>
              </a-form-model-item>
              <!--TEXT-AREA -Especificación técnica -->
            </template>
            <!--  CAMPOS DE SEGURIDAD Y LABORATORIO-->
            <!--  CAMPOS EQUIPOS MOVILES-->
            <template v-else>
              <hr class="bg-primary">
              <!--TEXT - Modelo-->
              <a-form-model-item label="Modelo">
                  <a-input v-model.trim="resourceObj.form.specific_data.model"
                           placeholder="Modelo" size="large"/>
              </a-form-model-item>
              <!--TEXT -Modelo -->
              <a-row align="middle" justify="center">
                <a-col :sm="{ span: 24 }" :md="{ span:12}">
                  <!--NUMBER INPUT - Capacidad de Carga-->
                  <a-form-model-item label="Capacidad de Carga">
                    <a-input-number v-model.trim="resourceObj.form.specific_data.loading_capacity" style="width: 50%"
                                    :min="0" :step="1" size="large"/> TON
                  </a-form-model-item>
                  <!--NUMBER INPUT -Capacidad de Carga -->
                </a-col>
                <a-col :sm="{ span: 24 }" :md="{ span:12}">
                  <!--SELECT -Año -->
                  <a-form-model-item label="Año" ref="year">
                    <a-select v-model="resourceObj.form.specific_data.year"
                              placeholder="Año"
                              show-search :filter-option="filterOption">
                      <a-select-option  v-for="option in yearsModels()" :key="option" :value="option" >
                        {{option}}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <!--SELECT -Año -->
                </a-col>
              </a-row>
              <a-row align="middle" justify="center">
                <a-col :sm="{ span: 24 }" :md="{ span:12}">
                  <!--DATEPICKER - Vigencia de Poliza de seguro-->
                  <a-form-model-item  label="Vigencia de Poliza de seguro">
                    <a-date-picker  v-model="resourceObj.form.specific_data.policy_validity" placeholder="Poliza de seguro"
                                    format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"  style="width: 100%"/>
                  </a-form-model-item>
                  <!--DATEPICKER - Vigencia de Poliza de seguro -->
                </a-col>
                <a-col :sm="{ span: 24 }" :md="{ span:12}">
                  <!--DATEPICKER - Vigencia tajeta de circulación-->
                  <a-form-model-item  label="Vigencia tajeta de circulación" >
                    <a-date-picker  v-model="resourceObj.form.specific_data.card_validity" placeholder="Tajeta de circulación"
                                    format="DD/MM/YYYY" size="large" valueFormat="YYYY/MM/DD"  style="width: 100%"/>
                  </a-form-model-item>
                  <!--DATEPICKER - Vigencia tajeta de circulación -->
                </a-col>
              </a-row>
            </template>
            <!--  CAMPOS EQUIPOS MOVILES-->
            <!--BOTONERA  -->
            <div class="row justify-content-end mr-2">
              <b-button-group>
                <b-button  variant="primary" @click="Submit()" pill>
                    <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/> Guardar
                    </div>
                    <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                        <b-icon icon="archive"/>
                    </div>
                </b-button>
                <b-button  variant="danger" @click="Delete()" pill v-if="resourceObj.form.id">
                    <div class="d-none d-md-block" v-b-tooltip.hover title="Eliminar">
                        <b-icon icon="trash"/> Eliminar
                    </div>
                    <div class="d-md-none" v-b-tooltip.hover title="Eliminar">
                        <b-icon icon="trash"/>
                    </div>
                </b-button>
              </b-button-group>
            </div>
            <!--BOTONERA  -->
          </a-form-model>
          <!--FORMULARIO-->
        </b-container>
     <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->
  </div>
</template>
<script>
import { fractalMixin } from '@/mixins/fractalMixin'
export default {
  name: 'auxiliarMasters',
  mixins: [fractalMixin],
  props: {
    auxiliar_master_type: {
      type: [String],
      required: true,
      validator: (value) => [
        'Equipos Móviles',
        'Equipos de Laboratorio',
        'Equipos de Seguridad',
        'Trituradoras',
        'Instalaciones',
      ].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      // Variables especificas por cada caso de uso
      filterValue: '',
      sortByOptions: [
        { value: '', text: 'Ordenar por', disabled: true },
        { value: 'code', text: 'Clave del Equipo' },
        { value: 'name', text: 'Nombre del Equipo' },
        { value: 'serial_number', text: 'No.Serie del Equipo' },
      ],
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          {
            title: 'Nombre',
            dataIndex: 'name',
            class: 'small text-center',
            // fixed: 'left',
            // width: '20%',
          },
          {
            title: 'Planta',
            dataIndex: 'workCenters',
            class: 'small text-center',
            scopedSlots: { customRender: 'workCenters' },
            // width: '20%',
          },
          {
            title: 'Detalles',
            dataIndex: '',
            class: 'small text-center',
            scopedSlots: { customRender: 'details' },
            // width: '20%',
          },
          {
            title: 'Acción',
            dataIndex: '',
            key: 'x',
            class: 'small text-center',
            scopedSlots: { customRender: 'action' },
            // fixed: 'right',
            // width: '20%',
          },
        ],
      },
      resourceObj: {
        resourceType: 'auxiliarMasters',
        form: {
          id: '',
          auxiliar_master_type: this.auxiliar_master_type,
          code: '', // Clave del equipo
          name: '', // Nombre del equipo
          workCenters: undefined, // Planta
          checksEquipmentSources: undefined, // CheckList
          dated_at: '', // Fecha de ingreso a planta
          serial_number: '', // No.Serie
          status: 0, // Estatus
          specific_data: {
            model: '',
            year: '',
            policy_validity: '',
            card_validity: '',
            loading_capacity: '',
          },
        },
        relationships: ['workCenters', 'families', 'checksEquipmentSources'],
      },
      formRules: {},
      // Combos para formulario
      familiesCombo: [],
      workCentersCombo: [],
      checksEquipmentSources: [],
      statusCombos: [{ value: 0, label: 'Inactivo' }, { value: 1, label: 'Activo' }],
      // Variables de flujo
      spinnerLoad: false,
      // Filtros
      filterWorkCenter: '',
      filterFamilies: '',
    }
  },
  methods: {
    // Funciones auxiliarres
    isMobile() {
      return this.auxiliar_master_type === 'Equipos Móviles'
    },
    isLaboratory() {
      return this.auxiliar_master_type === 'Equipos de Laboratorio'
    },
    isSecurity() {
      return this.auxiliar_master_type === 'Equipos de Seguridad'
    },
    // isInstallation() {
    //   return this.auxiliar_master_type === 'Instalaciones'
    // },
    // isShredder() {
    //   return this.auxiliar_master_type === 'Trituradoras'
    // },
    yearsModels() {
      const max = new Date().getFullYear()
      const years = []

      for (let i = max; i >= (max - 80); i--) {
        years.push(i.toString())
      }
      return years
    },
    // Funciones auxiliarres
    async FetchCombos() {
      const params = {
        'filter[family_type]': this.auxiliar_master_type,
        sort: 'name',
      }
      this.familiesCombo = await this.GetResource('/families', params)
      this.workCentersCombo = await this.GetResource('/workCenters', { sort: 'name' })
    },
    async FetchCheckListsCombos(workCenter = '') {
      const paramsChecks = { sort: 'name', 'filter[equipment_type]': this.auxiliar_master_type }
      if (workCenter !== '') {
        paramsChecks['filter[workCenters]'] = workCenter
      } else if (workCenter === '' && this.resourceObj.form.workCenters) {
        paramsChecks['filter[workCenters]'] = this.resourceObj.form.workCenters
      }
      this.checksEquipmentSources = await this.GetResource('/checksEquipmentSources', paramsChecks)
    },
    async FetchTable(pagEvent = {}) {
      const params = {
        'page[number]': (Object.keys(pagEvent).length !== 0) ? `${this.tableObj.pagination.current}` : `${this.tableObj.currentPage}`,
        'page[size]': `${this.tableObj.pagination.pageSize}`,
        sort: this.tableObj.sortValue,
        include: 'workCenters,families,checksEquipmentSources',
        'filter[global]': this.filterValue,
        'filter[type]': this.auxiliar_master_type,
      }
      if (this.filterWorkCenter !== '') params['filter[workCenters]'] = this.filterWorkCenter
      if (this.filterFamilies !== '') params['filter[families]'] = this.filterFamilies
      if (this.filterValue !== '') params['filter[global]'] = this.filterValue
      await this.GetTableResource(pagEvent, this.resourceObj.resourceType, this.tableObj, params)
    },
    async OpenModal(data = {}) {
      this.$refs['modal-Form'].show()
      if (Object.keys(data).length === 0) {
        //  Store case
        this.SetCustomStructures()
      } else {
        // Update case
        this.SetUpdateCases(data)
      }
      await this.FetchCheckListsCombos()
    },
    CloseModal() {
      this.$refs['modal-Form'].hide()
      this.SetCustomStructures()
    },
    async Submit() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.spinnerLoad = true
          if (this.resourceObj.form.id === '') {
            await this.PostResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
          } else await this.PutResource(this.resourceObj, this.SuccessCallback, () => { this.spinnerLoad = false })
        } else return false
      })
    },
    async Delete() {
      this.spinnerLoad = true
      await this.deleteQuestion(this.resourceObj.resourceType, this.resourceObj.form.id, this.SuccessCallback, () => { this.spinnerLoad = false })
    },
    async SuccessCallback() {
      this.spinnerLoad = false
      await this.FetchTable()
      this.CloseModal()
    },
    SetCustomStructures() {
      if (this.isMobile()) {
        this.resourceObj.form = {
          id: '',
          auxiliar_master_type: this.auxiliar_master_type,
          code: '', // Clave del equipo
          name: '', // Nombre del equipo
          workCenters: undefined, // Planta
          checksEquipmentSources: undefined, // CheckList
          dated_at: '', // Fecha de ingreso a planta
          serial_number: '', // No.Serie
          status: 0, // Estatus
          specific_data: {
            model: '', // Modelo
            year: '', // Año del modelo
            policy_validity: '', // Vigencia de Poliza de seguro
            card_validity: '', // Vigencia tajeta de circulación
            loading_capacity: '', // Capacidad de carga
          },
        }
        this.formRules = {
          code: [{ required: true, message: 'El campo Clave del Equipo es obligatorio.', trigger: 'blur' }],
          name: [{ required: true, message: 'El campo Nombre del Equipo es obligatorio.', trigger: 'blur' }],
          workCenters: [{ required: true, message: 'El campo Planta es obligatorio.', trigger: 'blur' }],
          dated_at: [{ required: true, message: 'El campo Fecha de ingreso a planta es obligatorio.', trigger: 'change' }],
          serial_number: [{ required: true, message: 'El campo  No.Serie  es obligatorio.', trigger: 'blur' }],
        }
      } else {
        this.resourceObj.form = {
          id: '',
          auxiliar_master_type: this.auxiliar_master_type,
          code: '', // Clave del equipo
          name: '', // Nombre del equipo
          workCenters: undefined, // Planta
          checksEquipmentSources: undefined, // CheckList
          dated_at: '', // Fecha de adquisición del equipo
          technical_specification: '', // Especificación técnica del equipo
          serial_number: '', // No.Serie
          details: '', // Detalles del equipo
          status: 0, // Estatus
          families: undefined, // Familia
          specific_data: [],
        }
        this.formRules = {
          code: [{ required: true, message: 'El campo Clave del Equipo es obligatorio.', trigger: 'blur' }],
          name: [{ required: true, message: 'El campo Nombre del Equipo es obligatorio.', trigger: 'blur' }],
          workCenters: [{ required: true, message: 'El campo Planta es obligatorio.', trigger: 'blur' }],
          families: [{ required: true, message: 'El campo Familia es obligatorio.', trigger: 'blur' }],
          dated_at: [{ required: true, message: 'El campo Fecha de adquisición es obligatorio.', trigger: 'change' }],
          serial_number: [{ required: true, message: 'El campo  No.Serie  es obligatorio.', trigger: 'blur' }],
        }
      }
    },
    SetUpdateCases(data) {
      if (this.isMobile()) {
        this.resourceObj.form = {
          id: data.id.toString(),
          auxiliar_master_type: this.auxiliar_master_type,
          code: data.code, // Clave del equipo
          name: data.name, // Nombre del equipo
          workCenters: data.workCenters.id.toString(), // Planta
          checksEquipmentSources: (data.checksEquipmentSources) ? data.checksEquipmentSources.id.toString() : undefined, // check list
          dated_at: data.dated_at, // Fecha de ingreso a planta
          serial_number: data.serial_number, // No.Serie
          status: data.status ? 1 : 0, // Estatus
          specific_data: {
            model: data.specific_data.model, // Modelo
            year: data.specific_data.year, // Año del modelo
            policy_validity: data.specific_data.policy_validity, // Vigencia de Poliza de seguro
            card_validity: data.specific_data.card_validity, // Vigencia tajeta de circulación
            loading_capacity: data.specific_data.loading_capacity, // Capacidad de carga
          },
        }
      } else {
        this.resourceObj.form = {
          id: data.id.toString(),
          auxiliar_master_type: this.auxiliar_master_type,
          code: data.code, // Clave del equipo
          name: data.name, // Nombre del equipo
          workCenters: data.workCenters.id.toString(), // Planta
          checksEquipmentSources: (data.checksEquipmentSources) ? data.checksEquipmentSources.id.toString() : undefined, // check list
          dated_at: data.dated_at, // Fecha de adquisición del equipo
          technical_specification: data.technical_specification, // Especificación técnica del equipo
          serial_number: data.serial_number, // No.Serie
          details: data.details, // Detalles del equipo
          status: data.status ? 1 : 0, // Estatus
          families: data.families.id.toString(), // Familia
          specific_data: [],
        }
      }
    },
    // GoTo CheckList
    GoToCheckLis(data) {
      const routeData = this.$router.resolve({ name: 'equipmentChecks', params: { checksEquipmentSource: data.checksEquipmentSources.id, auxiliarMaster: data.id } })
      window.open(routeData.href, '_blank')
    },
    GoToHistory(id) {
      const routeData = this.$router.resolve({ name: 'equipmentChecksHistory', params: { auxiliarMaster: id } })
      window.open(routeData.href, '_blank')
    },
  },
  async mounted() {
    this.SetCustomStructures()
    await this.FetchCombos()
    await this.FetchTable()
  },
}
</script>
<style scoped></style>
